<template>
  <div class="info">
    <div class="info_staff">
      <span>姓名</span>
      <p>{{ staffAllInfo.name }}</p>
      <span>电话号码</span>
      <p>{{staffAllInfo.tel}}</p>
<!--      <span>身份证号码</span>-->
<!--      <p>{{staffAllInfo.idCard}}</p>-->
      <span>场地</span>
      <p>{{staffAllInfo.place}}</p>
    </div>

<!--    <div class="info_gift" v-show="!staffAllInfo.isGift">-->
<!--      <p class="info_gift_title">物流信息</p>-->
<!--      <ul class="info_gift_detail">-->
<!--        <li><span>姓名</span><p>{{staffAllInfo.gift.name}}</p></li>-->
<!--        <li><span>电话</span><p>{{ staffAllInfo.gift.tel }}</p></li>-->
<!--        <li><span>地址</span><p>{{ staffAllInfo.gift.city[0]}}-->
<!--          {{ staffAllInfo.gift.city[1]=='市辖区'?'':staffAllInfo.gift.city[1]}}-->
<!--          {{ staffAllInfo.gift.city[2]}}-->
<!--          {{ staffAllInfo.gift.addr }}</p></li>-->

<!--      </ul>-->
<!--    </div>-->
    <div class="info_button">
      <button class="info_button_back" @click="goBack">返回修改</button>
      <button class="info_button_next" @click="toSubmit()">提交</button>
    </div>
    <Error v-if="isShow" @close="isShow=false" :message="message"></Error>
    <!--    确定场地-->
      <div class="checkbox_info_confirmed" v-show="isSubmit">
        <div class="checkbox_info_confirmed_content">
          <span class="cicc_i">i</span>
          <p class="cicc_p">请确认您的报名信息，提交报名后将无法更换活动场地。</p>
          <div class="cicc_button_content">
            <button class="cicc_cancel" @click="isCancel">返回修改</button>
            <button class="cicc_confirm" @click="isConfirm">确认提交</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
// import staff from "./staff";
import Error from "../../components/error";
export default {
  name: "info",
  components:{
    Error
  },
  data(){
    return{
      isShow:false,
      message:'',
      isSubmit:false
    }
  },
  mounted(){
    // this.staffAllInfo = this.$store.getters["register/getStaff"]
    // console.log(this.staffAllInfo)
    this.pageTitle('报名信息')
  },
  computed:{
    ...mapState({
      staffAllInfo:state => state.register.staff
      })
  },
  methods:{
    ...mapActions({
      pageTitle:'register/pageTitle',
      submitInfo:'register/submitInfo'
    }),
    goBack(){
      if (this.staffAllInfo.isEntourage){
        this.$router.push({name:'register.entourage'})
      }else{
        this.$router.push({name:'register.staff'})
      }
    },
    toSubmit(){
      this.isSubmit = true
    },

    // //取消\确认
    isCancel(){
      this.isSubmit = false
    },
    isConfirm(){
      this.$router.replace({name:'register.info'})
      this.submitInfo(this.staffAllInfo).then(()=>{
        this.$router.replace({name:'register.result'})
      }).catch((err)=>{
        this.isShow = true
        console.log(err);
        this.message = err.message
      });
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
p
  margin 0
ul,li
  list-style-type none
  padding 0
  margin 0
.info
  background-color white
  padding 0 38px 61px
  .info_staff
    padding-top 20px
    padding-bottom 20px
    text-align center
    span
      infoSpanSet()
    p
      infoPSet()
      font-size 48px
      padding-bottom 27px
  .info_entourage
    padding-bottom 60px

    .info_entourage_title
      text-align center
      font-size 24px
      font-family "source han sans cn"
      color #33CC00
      line-height 48px
      padding 12px 0 30px
    .info_entourage_item
      width 670px
      //height 65px
      background #F5F5F5
      border-radius 10px
      display flex
      flex-direction column
      justify-content space-between
      padding 25px 0 25px 37px
      margin-bottom 10px
      box-sizing border-box
      li
        display flex
        flex-direction row
        padding-bottom 10px
        span
          width 3em
          infoSpanSet()
          text-align: justify;
          text-align-last: justify;
          padding-right 28px
          line-height 34px
        p
          infoPSet()
          font-size 36px
          line-height 34px

  .info_gift
    border-top  2px solid #CCCCCC
    .info_gift_title
      titleSet()
      padding 55px 0
    .info_gift_detail
      height 180px
      display flex
      flex-direction column
      justify-content space-around
      li
        display flex
        flex-direction row
        padding-left 37px
        span
          width 3em
          infoSpanSet()
          text-align: justify;
          text-align-last: justify;
          padding-right 28px
          line-height 48px
        p
          width 520px
          infoPSet()
          font-size 36px
          line-height 48px
          word-wrap break-word
  .info_button
    margin-top 65px
    display flex
    flex-direction row
    justify-content space-between
    button
      buttonSet()
      width 329px
    .info_button_back
      color $gray
      background $bcGray
    .info_button_next
      color #FFF
  //确认框
  .checkbox_info_confirmed
    position fixed
    background-color rgba(0,0,0,0.6)
    top 0
    right 0
    bottom 0
    left 0
    display flex
    flex-direction column
    align-items center
    justify-content center
    .checkbox_info_confirmed_content
      width 600px
      height 400px
      background-color white
      border-radius 60px
      display flex
      flex-direction column
      align-items center
      justify-content center
      .cicc_i
        width 80px
        height 80px
        border-radius 50%
        text-align center
        line-height 80px
        background $error
        color white
        font-size 50px
        font-weight bold
      .cicc_p
        font-size 36px
        margin 50px 30px
        text-align center
      .cicc_button_content
        button
          buttonSet()
          width 250px
          margin 0 10px
          font-size 30px
        .cicc_cancel
          color $gray
          background $bcGray
        .cicc_confirm
          color #FFF
</style>
